import { useEffect } from 'react'
import Cover from '../../assets/img/cover.png'
import Cover2 from '../../assets/img/iMac.png'
import Cover3 from '../../assets/img/MacBook.png'
import BrushIcon from '../../assets/svg/BrushIcon';
import { NavLink } from 'react-router-dom';

export const Service = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
  return (
    <section id='service'>
        <div className='service wrapper container  mx-auto px-5 md:px-3 mt-10'>
        <div className='breadcrumb mb-8'>
            <ul>
              <li><NavLink to="/">Home</NavLink></li>
              <li>Service</li>
            </ul>
          </div>
            <div className="service--list grid grid-cols-1 sm:grid-cols-2 mb-10">
                <div className='thumbnail'>
                    <div className='title-thumbnail'>
                        <p>Tech Stack</p>
                        <ul className='techStack'>
                                <li>
                                    <img src="https://i.postimg.cc/bvrqjZTG/bootstrap.png" alt='img'/>
                                </li>
                                <li>
                                    <img src="https://i.postimg.cc/tTVyY88p/css3.png" alt='img'/>
                                </li>
                                <li>
                                    <img src="https://i.postimg.cc/Y06HxYpP/html5.png" alt='img'/>
                                </li>
                                <li>
                                    <img src="https://i.postimg.cc/6qJKRYNR/javascript.png" alt='img'/>
                                </li>
                        </ul>
                    </div>
                    <div className='cover'>
                        <img src={Cover} alt='img'/>
                    </div>
                </div>
                <div className='overview px-0 sm:px-5'>
                    <div className='breadcrumb mb-5'>
                        <ul >
                            <li>Frontend Developer</li>
                            <li>Slicing</li>
                        </ul>
                    </div>
                    
                    <h1 className='text-2xl sm:text-4xl'>I will do slicing design figma or xd to HTML with bootstrap 5</h1>
                    <h2 className='mt-4 mb-2'>Description</h2>                    
                    <p className='desc  mb-4'>I will slicing your design with Bootstrap 5, you can message me and probably share your design first before you choose my gig and I will check the design to see if possible to me do it or not, this project availble on Fiverr</p>
                    <h2 className='mb-2'>Category</h2>
                    <ul className='cat mb-10 flex flex-wrap gap-3 items-center'>
                        <li>Freelance</li>
                        <li>Fiverr</li>
                        <li>Comapny Profil</li>
                        <li>Personal Website</li>
                        <li>E-commerce</li>
                        <li>E-learning</li>
                    </ul>
                    <a href="https://www.fiverr.com/diaslaksmana/do-slicing-design-figma-or-xd-to-html-with-bootstrap-5" target='_blank' className='btn--custom'>View Detail</a>
                </div>
            </div>

            <div className="service--list grid grid-cols-1 sm:grid-cols-2 mb-10">
                <div className='thumbnail'>
                    <div className='title-thumbnail'>
                        <p>Tech Stack</p>
                        <ul className='techStack'>
                                <li>
                                    <img src="https://i.postimg.cc/bvrqjZTG/bootstrap.png" alt='img'/>
                                </li>
                                <li>
                                    <img src="https://i.postimg.cc/tTVyY88p/css3.png" alt='img'/>
                                </li>
                                <li>
                                    <img src="https://i.postimg.cc/Y06HxYpP/html5.png" alt='img'/>
                                </li>
                                <li>
                                    <img src="https://i.postimg.cc/6qJKRYNR/javascript.png" alt='img'/>
                                </li>
                        </ul>
                    </div>
                    <div className='cover'>
                        <img src={Cover3} alt='img'/>
                    </div>
                </div>
                <div className='overview px-0 sm:px-5'>
                <div className='breadcrumb mb-5'>
                        <ul >
                            <li>Frontend Developer</li>
                            <li>Slicing</li>
                        </ul>
                    </div>
                    <h1 className='text-2xl sm:text-4xl'>I will slicing figma to HTML, xd to HTML responsive bootstrap 5 (Single page)</h1>
                    <h2 className='mt-4 mb-2'>Description</h2>                    
                    <p className='desc  mb-4'>I will slicing your design with Bootstrap 5, you can message me and probably share your design first before you choose my gig and I will check the design to see if possible to me do it or not, this project availble on Fiverr</p>
                    <h2 className='mb-2'>Category</h2>
                    <ul className='cat mb-10 flex flex-wrap gap-3 items-center'>
                        <li>Freelance</li>
                        <li>Fiverr</li>
                        <li>Comapny Profil</li>
                        <li>Personal Website</li>
                    </ul>
                    <a href="https://www.fiverr.com/diaslaksmana/convert-figma-to-html-xd-to-html-responsive-bootstrap-5-17c8" target='_blank' className='btn--custom'>View Detail</a>
                </div>
            </div>

            <div className="service--list grid grid-cols-1 sm:grid-cols-2">
                <div className='thumbnail'>
                    <div className='title-thumbnail'>
                        <p>Tech Stack</p>
                        <ul className='techStack'>
                                <li>
                                    <img src="https://i.postimg.cc/kMKq93xy/react.png" alt='img'/>
                                </li>
                                <li>
                                    <img src="https://i.postimg.cc/tCby4SkG/typescript.png"alt='img'/>
                                </li>
                                <li>
                                    <img src="https://i.postimg.cc/3xn36Xg5/tailwind-css.png" alt='img'/>
                                </li>
                                <li>
                                    <img src="https://i.postimg.cc/cJz01d9F/sass.png" alt='img'/>
                                </li>
                        </ul>
                    </div>
                    <div className='cover'>
                        <img src={Cover2} alt='img'/>
                    </div>
                </div>
                <div className='overview px-0 sm:px-5 '>
                <div className='breadcrumb mb-5'>
                        <ul >
                            <li>Frontend Developer</li>
                            <li>Dashboard</li>
                        </ul>
                    </div>
                    <h1 className='text-2xl sm:text-4xl'>Template Dashboard</h1>
                    <h2 className='mt-4 mb-2'>Description</h2>
                    <p className='desc mb-10'>Template Dashboard using React and typescript, the feautre can be switch mode dark and ligh mode. There is an feature change the color theme and setting background sidebar. Have you interisting ? let's contact Me</p>
                    <h2 className='mb-2'>Category</h2>
                    <ul className='cat mb-10 flex flex-wrap gap-3 items-center'>
                        <li>Freelance</li>
                        <li>Personal Project</li>
                        <li>Custom Dashboard</li>
                    </ul>
                    <a href="https://my-app-dashbaoard.netlify.app/" target='_blank' className='btn--custom '>View Detail</a>
                </div>
            </div>

        </div>
  </section>
  )
}

export default Service