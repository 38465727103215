import Img from '../../../assets/gif/flame-space-adventures.gif'

export const NoData = () => {
  return (
    <div id='error'>
        <div className='wrapper container mx-auto px-5 flex flex-col items-center justify-center'>
            <img src={Img} alt='img'/>
            <p className='text-2xl font-semibold'>Opps .... Can't find the data</p>
        </div>
    </div>
  )
}

export default NoData