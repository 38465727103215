import { DataPortfolio } from "../../../constan/DataPortfolio";
import useTechData from "../../../hooks/FetchData";
import { NavLink } from "react-router-dom";

export const Ecommerce = () => {
  const filteredData = DataPortfolio.filter(
    (entry: any) => entry.category === "Ecommerce"
  );
  return (
    <div className="ecommerce">
      {DataPortfolio && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredData?.map((item, index) => (
            <div key={index} className="card">
              <NavLink to=""></NavLink>
              <div className="card--header">
                <img src={item.image} alt="cover" />
                <p className="desc">{item.description}</p>
              </div>
              <div className="card--body">
                <ul className="flex gap-2 techStack">
                  {item?.tech?.map((tech: any) => (
                    <li>#{tech.name}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default Ecommerce;
