import BrushIcon from "../../assets/svg/BrushIcon";
import Resource from "../layout/bookmark/Resource";
import TabPills from "../component/TabPills";
import Tools from "../layout/bookmark/Tools";
import Video from "../layout/bookmark/Video";
import { NavLink } from "react-router-dom";

export const Bookmark = () => {
  const tabs = [
    {
      id: 'tab1',
      title: 'Resource',
      content: <Resource/>,
    },
    {
      id: 'tab2',
      title: 'Tools',
      content: <Tools/>,
    },
    {
      id: 'tab3',
      title: 'Video Tutorial',
      content: <Video/>,
    },
  ];
  return (
    <section id='bookmark'>
      <div className='bookmark wrapper container  mx-auto px-5 md:px-3 mt-10'>
      <div className='breadcrumb mb-8'>
            <ul>
              <li><NavLink to="/">Home</NavLink></li>
              <li>Bookmark</li>
            </ul>
          </div>
        <TabPills tabs={tabs} />
      </div>
    </section>
   
  )
}

export default Bookmark
