import React from 'react'

export const Footer = () => {
    const currentYear = new Date().getFullYear();
  return (
    <footer id='footer'>
        <div className='container  mx-auto px-5 md:px-3 py-1 md:py-3 text-center'>
            <p className='text-xs'>Copyright © {currentYear} <span className='font-semibold'> Dwiky Dias Laksmana</span>. All Rights Reserved</p>
        </div>
    </footer>
  )
}

export default Footer