import { NavLink } from "react-router-dom";
import Hero from "../../assets/img/hero.png";
import About from "../layout/About";
import Lamp from "../../assets/img/lamp.png";
import Contact from "../layout/Contact";

export const Home = () => {
  return (
    <>
      <section id="hero" className="relative">
        <div className="lamp">
          <img src={Lamp} alt="lamp" />
        </div>
        <div className="hero wrapper container  mx-auto px-5 md:px-3">
          <div className="heroTitle">
            <p className="heading text-2xl sm:text-4xl">
              Hey! I'm <b>Dias</b>
            </p>
            <h1 className="subheading text-3xl sm:text-7xl lg:text-8xl my-4">
              Front-end Developer
            </h1>
            <p className="desc text-base sm:text-2xl mb-10">
              Crafting Interactive <b>Experiences</b> with Code
              <br />
              and Let's <b>Collaborate</b> with Me
            </p>
            <a href="#contact" className="btn btn-primary">
              Get in Touch
            </a>
          </div>
          <div className="heroImg">
            <div className="heroImg--cover">
              <span className="box--overlay sass">
                <img src="https://i.ibb.co/gdzvRkx/css.png" alt="img" />
                SASS
              </span>
              <span className="box--overlay html">
                <img src="https://i.ibb.co/6HwJV0K/html.png" alt="img" />
                HTML
              </span>
              <span className="box--overlay js">
                <img src="https://i.ibb.co/qR9WN2y/js.png" alt="img" />
                Javascript
              </span>
              <img src={Hero} alt="hero" />
            </div>
          </div>
        </div>
      </section>

      <About />

      <Contact />
    </>
  );
};
export default Home;
