import Cover from '../../assets/img/medim.png'
import Cover2 from '../../assets/img/sass.png'
import Right from '../../../assets/svg/Right'
import useFetchBookmark from '../../../hooks/FetchBookmark';

export const Resource = () => {
    const techData = useFetchBookmark();

    const filteredData = techData.filter((entry:any) => entry.category === 'resource');
    console.log(filteredData)
    return (
      <div className='bookmark--content'>
        
        {techData && (
        <ul>
           {filteredData?.map((item, index) => (
              <li key={index} >
               
                  <div className='card'>
                      <div className='card--header'>
                          <div className='thumbnail'>
                              <img src={item.image}/>
                          </div>
                      </div>
                      <div className='card--body'>
                          <h1 className='title'>{item.name}</h1>
                          <p className='decs mb-4'>{item.description}</p>
                          <a href={item.link} target='_blank' className='flex items-center gap-2'>See article <Right/></a>
                      </div>
                  </div>
              </li>
               ))}   
          </ul>
          
          )}
      </div>
    )
}

export default Resource