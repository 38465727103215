import { Link } from 'react-router-dom'
import Img from '../../../assets/img/flame-1862.png'

export const NotFound = () => {
  return (
    <div id='error'>
        <div className='wrapper container h-[100vh] mx-auto px-5 flex flex-col items-center justify-center'>
            <img src={Img} alt='img'/>
            <p className='text-2xl font-semibold my-5'>Opps .... Page Not Found</p>
            <Link to="/">back to Home</Link>
        </div>
    </div>
  )
}

export default NotFound
