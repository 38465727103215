
export const Loading = () => {
  return (
    <div className="loading-screen">
        <div className='warpper flex flex-col gap-8'>
            <p className='text-base sm:text-5xl font-semibold'>console.log("<span>Loading.....</span>")</p>           
        </div>       
    </div>
  )
}

export default Loading