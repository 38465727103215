import { useEffect, useState } from 'react';
import './App.css';
import Router from './router/Index';
import Footer from './view/layout/Footer';
import Header from './view/layout/Header';
import Loading from './view/layout/Loading';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading process for 2 seconds
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    // Cleanup the timeout when the component unmounts
    return () => clearTimeout(loadingTimeout);
  }, []);
  return (
    <>
    {isLoading ? <Loading /> : 
      <>
        <Header/>
        <Router/>
        <Footer/>
      </>
    }
     
    </>
  );
}

export default App;
