
import { Route, Routes } from "react-router-dom";
import Blog from "../view/page/Blog";
import Bookmark from "../view/page/Bookmark";
import Home from "../view/page/Home";
import Portfolio from "../view/page/Portfolio";
import Detail from "../view/page/Detail";
import NotFound from "../view/page/Error/NotFound";
import Service from "../view/page/Service";

const Router = () => {
    return (
      <Routes >
          <Route  path="" element={<Home />}/>   
          <Route  path="bookmark" element={<Bookmark />}/>   
          <Route  path="blog" element={<Blog />}/>  
          <Route  path="/detail/:id" element={<Detail />}/>   
          <Route  path="/portfolio" element={<Portfolio />}/>   
          <Route  path="/service" element={<Service />}/>   
          <Route  path="*" element={<NotFound />}/>   
      </Routes>
    )
  }
  export default Router;