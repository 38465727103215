import NoData from "../../page/Error/NoData"


export const Tools = () => {
  return (
    <div className='bookmark--content'>
        <NoData/>
    </div>
  )
}

export default Tools