export const DataPortfolio = [
  {
    id: 1,
    name: "Property",
    type_project: "Freelance",
    label: "Personal Project",
    description:
      "At XYZ Properties, we are committed to creating homes and communities that go beyond bricks and mortar. With a focus on quality, sustainability, and contemporary design, we strive to exceed the expectations of our clients and contribute to the development of vibrant, thriving neighborhoods.",
    link: "http",
    category: "Company Profile",
    image: "https://i.ibb.co/BzPk03F/property.png",
    tech: [
      {
        name: "CSS",
      },
      {
        name: "HTML",
      },
      {
        name: "JAVASCRIPT",
      },
      {
        name: "BOOTSTRAP",
      },
    ],
  },
  {
    id: 2,
    name: "Stockshop ",
    type_project: "Freelance",
    label: "Personal Project",
    description:
      "Stockshop is a cutting-edge e-commerce platform that revolutionizes the online shopping experience. Our platform is designed to cater to the diverse needs of modern consumers, offering a seamless and user-friendly interface for a hassle-free shopping journey",
    link: "http",
    category: "Ecommerce",
    image: "https://i.ibb.co/dcFn7mP/stockshop.jpg",
    tech: [
      {
        name: "CSS",
      },
      {
        name: "HTML",
      },
      {
        name: "JAVASCRIPT",
      },
      {
        name: "BOOTSTRAP",
      },
    ],
  },
  {
    id: 3,
    name: "Chapter1 ",
    type_project: "Freelance",
    label: "Project Freelance at Kadang Koding",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since",
    link: "http",
    category: "Company Profile",
    image: "https://i.ibb.co/Q6Tqdy8/Chapter1.jpg",
    tech: [
      {
        name: "CSS",
      },
      {
        name: "HTML",
      },
      {
        name: "JAVASCRIPT",
      },
      {
        name: "BOOTSTRAP",
      },
    ],
  },
  {
    id: 4,
    name: "Q7 ",
    type_project: "Frontier",
    label: "",
    description:
      "Q7 Survey helps you make it easier to create surveys, so you can get maximum results.",
    link: "http",
    category: "Company Profile",
    image: "https://i.ibb.co/tKVbwbq/Q7.jpg",
    tech: [
      {
        name: "CSS",
      },
      {
        name: "HTML",
      },
      {
        name: "JAVASCRIPT",
      },
    ],
  },
  {
    id: 5,
    name: "Spradz ",
    type_project: "Frontier",
    label: "",
    description:
      "Spradz! helping brands not only gain awareness but also conversions and Spradz! helping influencers to earn optimal income with professionals!",
    link: "http",
    category: "Other",
    image:
      "https://i.ibb.co/cc4zwJF/screencapture-dev-spradz-id-2020-03-05-10-57-53.png",
    tech: [
      {
        name: "CSS",
      },
      {
        name: "HTML",
      },
      {
        name: "JAVASCRIPT",
      },
    ],
  },
  {
    id: 6,
    name: "Technolabs ",
    type_project: "Freelance",
    label: "Personal Project",
    description:
      "At Technolabs, we understand that each client is unique, with specific needs and goals. Our client-centric approach involves close collaboration, transparent communication, and a commitment to delivering solutions that align with our clients' objectives. We believe in building long-term partnerships based on trust, reliability, and mutual successLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since",
    link: "http",
    category: "Company Profile",
    image: "https://i.ibb.co/qCrjrBc/technolabs.png",
    tech: [
      {
        name: "CSS",
      },
      {
        name: "HTML",
      },
      {
        name: "JAVASCRIPT",
      },
      {
        name: "WORDPRESS",
      },
    ],
  },
  {
    id: 7,
    name: "Teman Belajar ",
    type_project: "Freelance",
    label: "Project Freelance at Kadang Koding",
    description:
      "Find the best private tutors at affordable prices for all levels. Face-to-face and online learning methods. 99% of users are satisfied.",
    link: "http",
    category: "Other",
    image: "https://i.ibb.co/Fwr0ktp/projek5.jpg",
    tech: [
      {
        name: "CSS",
      },
      {
        name: "HTML",
      },
      {
        name: "JAVASCRIPT",
      },
      {
        name: "BOOTSTRAP",
      },
    ],
  },
  {
    id: 8,
    name: "Capital",
    type_project: "Freelance",
    label: "Project Freelance at Kadang Koding",
    description: "A SOLID AND TRUSTED INTEGRATED FINANCIAL COMPANY",
    link: "http",
    category: "Company Profile",
    image: "https://i.ibb.co/D5KDB2J/capital.jpg",
    tech: [
      {
        name: "CSS",
      },
      {
        name: "HTML",
      },
      {
        name: "JAVASCRIPT",
      },
      {
        name: "BOOTSTRAP",
      },
    ],
  },
  {
    id: 9,
    name: "Darmawan",
    type_project: "Freelance",
    label: "Project Freelance at Kadang Koding",
    description:
      "We are trusted by a group of international lenders to act as their Indonesian legal counsel in extending a loan facility to Digi Asia Group, one of the leading financial technology group companies which operates e-money business (KasPro), peer-to-peer lending business (KreditPro) and remittance business (RemitPro) through its subsidiaries in Indonesia.",
    link: "http",
    category: "Company Profile",
    image: "https://i.ibb.co/RgY90X4/darmawan.png",
    tech: [
      {
        name: "CSS",
      },
      {
        name: "HTML",
      },
      {
        name: "JAVASCRIPT",
      },
      {
        name: "BOOTSTRAP",
      },
    ],
  },
  {
    id: 10,
    name: "Dashboard Spardz",
    type_project: "Freelance",
    label: "Personal Project",
    description: "it's a dashboard admin for webiste Spradz",
    link: "http",
    category: "Other",
    image: "https://i.ibb.co/khrS5v5/Dashboard.png",
    tech: [
      {
        name: "CSS",
      },
      {
        name: "HTML",
      },
      {
        name: "JAVASCRIPT",
      },
      {
        name: "BOOTSTRAP",
      },
    ],
  },
  {
    id: 11,
    name: "Pickpict",
    type_project: "Freelance",
    label: "Personal Project",
    description:
      "The designers' website sells their designs in either softcopy or hardcopy. in our products we can also print various kinds of products from clothes, cell phone cashing, MUGs etc.",
    link: "http",
    category: "Other",
    image: "https://i.ibb.co/Z62Yg04/pickpict.png",
    tech: [
      {
        name: "CSS",
      },
      {
        name: "HTML",
      },
      {
        name: "JAVASCRIPT",
      },
      {
        name: "BOOTSTRAP",
      },
    ],
  },
  {
    id: 12,
    name: "Weldon",
    type_project: "Freelance",
    label: "Project Freelance at Kadang Koding",
    description:
      "PT. Citra Warna Abadi (CWA) was founded in 2010 and is a national company engaged in the production of wall paint and coating materials with superior decorative paint products. PT. Citra Warna Abadi (CWA) which is located on Jl. Bayu No.9 Rt.03 / 10 Sudimoro Parangjoro, Sukoharjo, Central Java, is committed to customer satisfaction, by producing high quality wall paint products and building an effective, efficient and mutually beneficial business network.",
    link: "http",
    category: "Company Profile",
    image: "https://i.ibb.co/zFpFSdK/projek7.jpg",
    tech: [
      {
        name: "CSS",
      },
      {
        name: "HTML",
      },
      {
        name: "JAVASCRIPT",
      },
      {
        name: "BOOTSTRAP",
      },
    ],
  },
  {
    id: 13,
    name: "Giladiskon",
    type_project: "Frontier",
    label: "",
    description:
      "Information on promos, discounts, online promos, promo codes from selected brands and merchants. Shop economically only with Giladiskon",
    link: "http",
    category: "Other",
    image:
      "https://i.ibb.co/zfmdTB9/screencapture-giladiskon-2020-03-05-11-03-03.png",
    tech: [
      {
        name: "CSS",
      },
      {
        name: "HTML",
      },
      {
        name: "JAVASCRIPT",
      },
      {
        name: "BOOTSTRAP",
      },
      {
        name: "LARAVEL",
      },
    ],
  },
  {
    id: 15,
    name: "Social Media Awward",
    type_project: "Frontier",
    label: "",
    description:
      "In today's digital era, customer perceptions are often formed through interactions, experiences and conversations they get or share on social media. In relation to business, companies need to realize that they do not have full control over the conversations that occur on social media. Therefore, companies must listen to the conversations that are happening, especially about them among customers, then join in those conversations.",
    link: "http",
    category: "Company Profile",
    image: "https://i.ibb.co/wwBy0v3/Screenshot-2023-11-23-230441.png",
    tech: [
      {
        name: "CSS",
      },
      {
        name: "HTML",
      },
      {
        name: "JAVASCRIPT",
      },
      {
        name: "BOOTSTRAP",
      },
    ],
  },
  {
    id: 16,
    name: "Movie",
    type_project: "Freelance",
    label: "Personal Project",
    description:
      "it's my personal project about movie for information release date, update move, and another",
    link: "http",
    category: "Other",
    image: "https://i.ibb.co/2NBLqK7/movie.png",
    tech: [
      {
        name: "SCSS",
      },
      {
        name: "Tailwind",
      },
      {
        name: "React JS",
      },
      {
        name: "Typescript",
      },
    ],
  },
  {
    id: 17,
    name: "Frontier Integrated Research Engine",
    type_project: "Frontier",
    label: "",
    description: "application survey website and mobile",
    link: "http",
    category: "Other",
    image: "",
    tech: [
      {
        name: "CSS",
      },
      {
        name: "HTML",
      },
      {
        name: "JAVASCRIPT",
      },
      {
        name: "CodeIgniter",
      },
    ],
  },
  {
    id: 18,
    name: "Giftkita",
    type_project: "Frontier",
    label: "",
    description: "Gift Management System to increase your customer loyalty",
    link: "http",
    category: "Other",
    image:
      "https://i.ibb.co/gd7dg4K/screencapture-localhost-giftkita-2020-03-05-09-59-44.png",
    tech: [
      {
        name: "CSS",
      },
      {
        name: "HTML",
      },
      {
        name: "JAVASCRIPT",
      },
      {
        name: "CodeIgniter",
      },
      {
        name: "Bootstrap",
      },
    ],
  },
  {
    id: 19,
    name: "Restodepo",
    type_project: "Frontier",
    label: "",
    description: "website selling basic food ingredients",
    link: "http",
    category: "Other",
    image: "",
    tech: [
      {
        name: "CSS",
      },
      {
        name: "HTML",
      },
      {
        name: "JAVASCRIPT",
      },
      {
        name: "CodeIgniter",
      },
    ],
  },
  {
    id: 20,
    name: "Website Property Homes",
    type_project: "Software Seni",
    label: "",
    description:
      "website for buying, selling and rent property home in Australia and New Zealand",
    link: "http",
    category: "",
    image: "",
    tech: [
      {
        name: "Wordpress",
      },
      {
        name: "HTML",
      },
      {
        name: "JAVASCRIPT",
      },
      {
        name: "CSS",
      },
    ],
  },
  {
    id: 20,
    name: "Boatdeck",
    type_project: "Software Seni",
    label: "",
    description:
      " website for buying, selling and rent boat/machine boats in Australia ",
    link: "http",
    category: "",
    image: "",
    tech: [
      {
        name: "Wordpress",
      },
      {
        name: "HTML",
      },
      {
        name: "JAVASCRIPT",
      },
      {
        name: "CSS",
      },
    ],
  },
  {
    id: 21,
    name: "Proposalpoint",
    type_project: "Software Seni",
    label: "",
    description:
      " ProposalPoint transforms how you present your service proposition to prospective clients. It allows you to create custom, modular & interactive digital real estate proposals or quotes with a unique link. ",
    link: "http",
    category: "",
    image: "",
    tech: [
      {
        name: "Wordpress",
      },
      {
        name: "HTML",
      },
      {
        name: "JAVASCRIPT",
      },
      {
        name: "CSS",
      },
    ],
  },
  {
    id: 22,
    name: "CET(customer Engagement Tools)",
    type_project: "Software Seni",
    label: "",
    description:
      " website for search engines buy and rent property home in Australia and New Zealand ",
    link: "http",
    category: "",
    image: "",
    tech: [
      {
        name: "Laravel",
      },
      {
        name: "HTML",
      },
      {
        name: "JAVASCRIPT",
      },
      {
        name: "SCSS",
      },
    ],
  },
];
