import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface Item {
  id?:number;
  name:string;
}


export const Detail = () => {
  const [data, setData] = useState<Item[]>([]);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    // Fetch data from the API
    fetch("https://json.extendsclass.com/bin/27250875aff7")
      .then((response) => response.json())
      .then((data) => setData(data));
  }, []);

  const filteredData = data.filter((item) => item.id === id);


  return (
    <section id='detail'>
      <div className='detail wrapper container mx-auto px-5'>
      {filteredData.map((item) => (
        <div key={item.id}>
          <h2>{item.name}</h2>
          {/* Display other data fields as needed */}
        </div>
      ))}
      {/* <h1>{data?.name}</h1> */}
     
      </div>
    </section>
  )
}

export default Detail