import { useState, useEffect } from 'react';

interface TechData {
  id: number;
  name: string;
  image: string;
  link: string;
  description: string;
}

const useFetchBookmark = () => {
  const [techData, setTechData] = useState<TechData[]>([]);
  const apiUrl = 'https://json.extendsclass.com/bin/2c62043d58f5';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setTechData(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return techData;
};

export default useFetchBookmark;
