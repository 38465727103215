import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import CV from "../../assets/img/Dias_CV.jpg";
import Product6 from "../../assets/img/dashboard2.png";
import Product7 from "../../assets/img/movie.png";
import Product4 from "../../assets/img/projek7.jpg";
import Product1 from "../../assets/img/property.png";
import Product2 from "../../assets/img/stockshop.jpg";
import Quotes from "../../assets/svg/Quotes";
import Right from "../../assets/svg/Right";
import Modal from "../component/Modal";

export const About = () => {
  const aboutRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (location.hash === "#about" && aboutRef.current) {
      const headerHeight = 64; // Adjust this value to match your header's height
      const padding = 20; // Adjust this value to add the desired padding at the top
      const offset = aboutRef.current.getBoundingClientRect().top;
      const scrollPosition = offset - headerHeight - padding;
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [location]);
  return (
    <section id="about" ref={aboutRef}>
      <div className="about container mx-auto px-5">
        <div className="introduce">
          <h1 className=" text-6xl mb-3">About </h1>

          <p className="subheading">Hi, I Dwiky Dias Laksmana.</p>
          <p className="subheading">
            I'm working professionally at the company as Frontend Developer for
            around 6 years and I'm available as a freelance Frontend Developer.
            Throughout my five years in the field, I have honed my skills in
            HTML, CSS, and JavaScript, and I have an understanding of frontend
            frameworks like React.
            <br />
          </p>
          {/* <p className="subheading">
            Currently I working as a <b>Front-end Developer</b> at
            <a href="">Software Seni</a> and I'm available too for freelance as
            a Front-end developer.
            <br />
          </p> */}
          <p className="subheading">
            I also available as freelance on Fiverr, you can see on{" "}
            <NavLink to="/service" className=" font-semibold underline fiverr">
              this
            </NavLink>
          </p>
          {/* <h3 className="text-2xl mt-4 mb-2">Work Experience</h3>
          <p className="subheading">
            My History working related as <b>Front-end Developer</b>
          </p>

          <ul className="block sm:flex gap-5 items-center flex-row history--experience">
            <li>
              <div className="card">
                <div className="card--header">
                  <img src={Code} alt="img" />
                </div>
                <div className="card--body">
                  <h1>Freelance Frontend Developer</h1>
                  <p>2017 - Current</p>
                </div>
              </div>
            </li>
            <li>
              <div className="card">
                <div className="card--header">
                  <img src={FA} alt="img" />
                </div>
                <div className="card--body">
                  <h1>Frontier Apps </h1>
                  <p>2018 - 2020</p>
                </div>
              </div>
            </li>
            <li>
              <div className="card">
                <div className="card--header">
                  <img src={SS} alt="img" />
                </div>
                <div className="card--body">
                  <h1>Software Seni</h1>
                  <p>2020 - Current</p>
                </div>
              </div>
            </li>
          </ul> */}
          {/* <h3 className="text-2xl mt-4 mb-2">Follow Me</h3>
          <ul className="flex items-center flex-wrap gap-5">
            <li>
              <div className="card--timeline">
                <a
                  href="https://www.linkedin.com/in/dwikydiasl/"
                  target="_blank"
                >
                  <span>
                    <Linkedin />
                  </span>
                  <p>Linkedin</p>
                </a>
              </div>
            </li>
            <li>
              <div className="card--timeline">
                <a href="https://www.instagram.com/dwikydiasl" target="_blank">
                  <span>
                    <Instagram />
                  </span>
                  <p>Instagram</p>
                </a>
              </div>
            </li>
            <li>
              <div className="card--timeline">
                <a href="https://gitlab.com/diaslaksmana" target="_blank">
                  <span>
                    <Gitlab />
                  </span>
                  <p>Gitlab</p>
                </a>
              </div>
            </li>
            <li>
              <div className="card--timeline">
                <a href="https://github.com/dwikydiasl" target="_blank">
                  <span>
                    <Github />
                  </span>
                  <p>Github</p>
                </a>
              </div>
            </li>
            <li>
              <div className="card--timeline">
                <button onClick={openModal}>
                  <span>
                    <Doc />
                  </span>
                  <p>My Cv</p>
                </button>
              </div>
            </li>
          </ul> */}
          <Modal isOpen={isOpen} onClose={closeModal}>
            <img src={CV} alt="cv" />
          </Modal>
        </div>
        <div className="introduce--project mt-16">
          <div className="introduce--projectList grid lg:grid-cols-3 grid-cols-1 gap-10">
            <div className="card--project grid items-center">
              <p className="text-3xl sm:text-5xl text font-semibold  leading-tight">
                This is my portfolio and previous projects that I have worked
                on, you can see my showcase project
              </p>
              <NavLink to="/portfolio" className="font-semibold">
                See all portfolio <Right />
              </NavLink>
            </div>
            <div className="card--project">
              <div className="thumbnail">
                <a
                  href="https://property-insight.netlify.app/"
                  target="_blank"
                ></a>
                <img src={Product1} className="cover" />
                <ul className="techStack">
                  <li>
                    <img
                      src="https://i.ibb.co/HFpqdzf/bootstrap-5968672.png"
                      alt="img"
                    />
                  </li>
                  <li>
                    <img src="https://i.ibb.co/7NSsX82/CSS-3.png" alt="img" />
                  </li>
                  <li>
                    <img src="https://i.ibb.co/6HwJV0K/html.png" alt="img" />
                  </li>
                  <li>
                    <img src="https://i.ibb.co/qR9WN2y/js.png" alt="img" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="card--project">
              <div className="thumbnail">
                <a href="https://stockshop.netlify.app/" target="_blank"></a>
                <img src={Product2} className="cover" />
                <ul className="techStack">
                  <li>
                    <img
                      src="https://i.ibb.co/HFpqdzf/bootstrap-5968672.png"
                      alt="img"
                    />
                  </li>
                  <li>
                    <img src="https://i.ibb.co/7NSsX82/CSS-3.png" alt="img" />
                  </li>
                  <li>
                    <img src="https://i.ibb.co/6HwJV0K/html.png" alt="img" />
                  </li>
                  <li>
                    <img src="https://i.ibb.co/qR9WN2y/js.png" alt="img" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="lg:col-span-2 col card--project">
              <div className="thumbnail">
                <a
                  href="https://my-app-dashbaoard.netlify.app/"
                  target="_blank"
                ></a>
                <img src={Product6} className="cover" />
                <ul className="techStack">
                  <li>
                    <img
                      src="https://i.ibb.co/d4307MJ/react-919851.png"
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src="https://i.ibb.co/nP6VQGN/typescript-5968381.png"
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src="https://i.ibb.co/xhXYjdT/9074204-tailwind-icon.png"
                      alt="img"
                    />
                  </li>
                  <li>
                    <img src="https://i.ibb.co/gdzvRkx/css.png" alt="img" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="card--project">
              <div className="thumbnail">
                <a href="https://ghiterra.netlify.app/" target="_blank"></a>
                <img
                  src="https://i.ibb.co/BZLh7Tw/screencapture-ghiterra-netlify-app-2023-12-13-01-58-01.png"
                  className="cover"
                />
                <ul className="techStack">
                  <li>
                    <img
                      src="https://i.ibb.co/d4307MJ/react-919851.png"
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src="https://i.ibb.co/nP6VQGN/typescript-5968381.png"
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src="https://i.ibb.co/xhXYjdT/9074204-tailwind-icon.png"
                      alt="img"
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className="card--project">
              <div className="thumbnail">
                <a href="https://movies-tech.netlify.app/" target="_blank"></a>
                <img src={Product7} className="cover" />
                <ul className="techStack">
                  <li>
                    <img
                      src="https://i.ibb.co/d4307MJ/react-919851.png"
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src="https://i.ibb.co/nP6VQGN/typescript-5968381.png"
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src="https://i.ibb.co/xhXYjdT/9074204-tailwind-icon.png"
                      alt="img"
                    />
                  </li>
                  <li>
                    <img src="https://i.ibb.co/gdzvRkx/css.png" alt="img" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-span-2 card--project">
              <div className="thumbnail">
                <a href="https://www.weldon.co.id/" target="_blank"></a>
                <img src={Product4} className="cover" />
                <ul className="techStack">
                  <li>
                    <img
                      src="https://i.ibb.co/HFpqdzf/bootstrap-5968672.png"
                      alt="img"
                    />
                  </li>
                  <li>
                    <img src="https://i.ibb.co/7NSsX82/CSS-3.png" alt="img" />
                  </li>
                  <li>
                    <img src="https://i.ibb.co/6HwJV0K/html.png" alt="img" />
                  </li>
                  <li>
                    <img src="https://i.ibb.co/qR9WN2y/js.png" alt="img" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="card--project">
              <div className="thumbnail">
                <a href="https://www.weldon.co.id/" target="_blank"></a>
                <img
                  src="https://i.ibb.co/SvcVfR3/Frame-1.png"
                  className="cover"
                />
                <ul className="techStack">
                  <li>
                    <img
                      src="https://i.ibb.co/HFpqdzf/bootstrap-5968672.png"
                      alt="img"
                    />
                  </li>
                  <li>
                    <img src="https://i.ibb.co/7NSsX82/CSS-3.png" alt="img" />
                  </li>
                  <li>
                    <img src="https://i.ibb.co/6HwJV0K/html.png" alt="img" />
                  </li>
                  <li>
                    <img src="https://i.ibb.co/qR9WN2y/js.png" alt="img" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="card--project">
              <div className="thumbnail">
                <a href="https://www.weldon.co.id/" target="_blank"></a>
                <img
                  src="https://i.ibb.co/KG953CW/Frame-2.png"
                  className="cover"
                />
                <ul className="techStack">
                  <li>
                    <img
                      src="https://i.ibb.co/xhXYjdT/9074204-tailwind-icon.png"
                      alt="img"
                    />
                  </li>
                  <li>
                    <img src="https://i.ibb.co/7NSsX82/CSS-3.png" alt="img" />
                  </li>
                  <li>
                    <img src="https://i.ibb.co/6HwJV0K/html.png" alt="img" />
                  </li>
                  <li>
                    <img src="https://i.ibb.co/qR9WN2y/js.png" alt="img" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="card--project">
              <div className="thumbnail">
                <a href="https://www.weldon.co.id/" target="_blank"></a>
                <img
                  src="https://i.ibb.co/J3QSJvk/Frame-3.png"
                  className="cover"
                />
                <ul className="techStack">
                  <li>
                    <img
                      src="https://i.ibb.co/xhXYjdT/9074204-tailwind-icon.png"
                      alt="img"
                    />
                  </li>
                  <li>
                    <img src="https://i.ibb.co/7NSsX82/CSS-3.png" alt="img" />
                  </li>
                  <li>
                    <img src="https://i.ibb.co/6HwJV0K/html.png" alt="img" />
                  </li>
                  <li>
                    <img src="https://i.ibb.co/qR9WN2y/js.png" alt="img" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="quotes">
          <p className="text-lg text-center mt-10">
            <Quotes /> Your work is going to fill a large part of your life, and
            the only way to be truly satisfied is to do what you believe is
            great work. And the only way to do great work is to love what you
            do. If you haven't found it yet, keep looking. Don't settle. As with
            all matters of the heart, you'll know when you find it.
          </p>
          <span>Steve Jobs</span>
        </div>
      </div>
    </section>
  );
};

export default About;
