import * as React from "react"

function Sun(props:any) {
  return (
    <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <style>{".cls-2{fill:#231815}"}</style>
      </defs>
      <g data-name="\u56FE\u5C42 1" id="\u56FE\u5C42_1">
        <path
          d="M320.17 298.35a81.5 81.5 0 00-54.33-121.91c-33.28-6.74-68.23 8.91-85.76 37.86a82.85 82.85 0 00-.52 84.06h140.61z"
          fill="#f8b62d"
        />
        <path
          className="cls-2"
          d="M331.77 256.69a83.48 83.48 0 01-11.6 41.66H337a97.63 97.63 0 009.78-41.66c.06 9.67-14.94 9.65-15.01 0z"
        />
        <path
          className="cls-2"
          d="M180.07 214.3c17.54-28.95 52.48-44.6 85.76-37.86a82.68 82.68 0 0165.94 80.25c.07-9.66 15.07-9.65 15 0a98 98 0 00-64-91.07C245 151.8 200.82 164 175.45 195.05c-23.69 29-28.74 69.75-12.79 103.3h16.88a82.85 82.85 0 01.53-84.05z"
        />
        <path
          className="cls-2"
          d="M331.77 256.69c.07 9.65 15.07 9.67 15 0s-14.93-9.69-15 0zM242.5 98.18v33.65c0 9.65 15 9.67 15 0V98.18c0-9.65-15-9.67-15 0zm7.5 33.65zM164.27 123.2l16.83 29.15c4.83 8.36 17.79.81 13-7.57l-16.83-29.15c-4.87-8.35-17.84-.81-13 7.57zm6.48-3.79l16.83 29.15zM116.51 171c-8.37-4.84-15.94 8.12-7.57 13l29.15 16.83c8.38 4.84 15.94-8.12 7.57-13zm-3.79 6.48l29.15 16.83zM91.49 264.19h33.65c9.65 0 9.67-15 0-15H91.49c-9.65 0-9.67 15 0 15zm33.65-7.5zM408.51 249.19h-33.65c-9.65 0-9.67 15 0 15h33.65c9.65 0 9.67-15 0-15zm-33.65 7.5zM383.49 171l-29.15 16.83c-8.36 4.83-.81 17.79 7.57 13L391.06 184c8.36-4.91.81-17.88-7.57-13zm-25.36 23.3l29.15-16.83zM322.78 115.63L306 144.78c-4.84 8.38 8.12 15.94 13 7.57l16.83-29.15c4.74-8.37-8.22-15.93-13.05-7.57zm-10.35 32.93l16.83-29.15zM407.32 298.35H337q-1.85 3.84-4 7.5h39.7v7.5h34.62c9.68 0 9.68-15 0-15zM92.68 305.85h74q-2.18-3.68-4-7.5h-70c-9.65 0-9.67 15 0 15h34.62v-7.5zM320.17 298.35H179.55a77.11 77.11 0 005 7.5h130.6a83.67 83.67 0 005.02-7.5z"
        />
        <path
          className="cls-2"
          d="M184.59 305.85a77.11 77.11 0 01-5-7.5h-16.93q1.81 3.82 4 7.5zM315.18 305.85H333q2.17-3.66 4-7.5h-16.83a83.67 83.67 0 01-4.99 7.5zM167.12 306.66c-.16-.27-.31-.54-.47-.81H127.3v7.5h44.28a84.498 84.498 0 01-4.46-6.69zM191.09 313.35h117.78a82.71 82.71 0 006.31-7.5H184.59q.92 1.21 1.89 2.38 2.2 2.68 4.61 5.12zM333 305.85q-2.29 3.86-4.91 7.5h44.61v-7.5z"
        />
        <path
          className="cls-2"
          d="M308.87 313.35H328q2.62-3.64 4.91-7.5h-17.73a82.71 82.71 0 01-6.31 7.5zM186.47 308.23q-1-1.17-1.89-2.38h-17.93c.16.27.31.54.47.81 1.4 2.31 2.89 4.54 4.46 6.69h19.51q-2.4-2.45-4.62-5.12zM375.15 346h-2.45v7.5h2.45c9.65 0 9.67-15 0-15h-2.45v7.5zM124.85 346h2.45v-7.5h-2.45c-9.65 0-9.67 15 0 15h2.45V346zM202.89 351.26l2.3 2.2h3z"
        />
        <path
          className="cls-2"
          d="M301.91 338.27a93.87 93.87 0 01-14.52 7.73h85.31v-7.5h-59.29c-3.8-.04-7.65-.18-11.5-.23zM291.8 353.46h3l2.3-2.2z"
        />
        <path
          className="cls-2"
          d="M291.8 346L250 386l-41.8-40h-80.9v7.5h77.89l-2.3-2.2 5.3 2.2h-3l39.51 37.81a7.65 7.65 0 0010.61 0l39.51-37.81h-3l5.3-2.2-2.3 2.2h77.88V346zM208.2 346h5.11a98.37 98.37 0 01-15.2-7.81c-2.38.14-4.62.31-6.43.31H127.3v7.5zM291.8 338.46c-4.33.3-7.23 4-10.17 6.86l-.63.68h6.43a93.87 93.87 0 0014.52-7.69c-3.42-.09-6.81-.08-10.15.15z"
        />
        <path
          className="cls-2"
          d="M213.65 340.79c-3.53-3.06-9.93-3-15.54-2.65a98.37 98.37 0 0015.2 7.81h6.14q-2.88-2.61-5.8-5.16zM255.3 380.66q-2.65-2.53-5.27-5.1l-5.33 5.1z"
        />
        <path
          className="cls-2"
          d="M268.47 357.91L250 375.55q2.63 2.57 5.27 5.1H244.7l5.33-5.1c-8.92-8.71-17.73-17.64-26.85-26.15a98.76 98.76 0 01-9.87-3.44h-5.11L250 386l41.8-40h-4.41a92.29 92.29 0 01-10.24 3.65z"
        />
        <path
          className="cls-2"
          d="M277.15 349.6a92.29 92.29 0 0010.24-3.6H281zM219.45 346h-6.14a98.76 98.76 0 009.87 3.44q-1.86-1.77-3.73-3.44zM291.8 378.57c-9.65 0-9.67 15 0 15h54.7c9.65 0 9.67-15 0-15zm0 7.5zM208.2 378.57h-54.7c-9.65 0-9.67 15 0 15h54.7c9.65 0 9.66-15 0-15zm-54.7 7.5z"
        />
      </g>
    </svg>
  )
}

export default Sun
