import * as React from "react"

function Moon(props:any) {
  return (
    <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <style>{".cls-3{fill:#231815}"}</style>
      </defs>
      <g data-name="\u56FE\u5C42 1" id="\u56FE\u5C42_1">
        <path
          d="M215.44 304.35h23.82l5.43-5.2a7.65 7.65 0 0110.61 0l5.43 5.2h23.82c0-3.75 2.42-7.5 7.24-7.5h44.08A121.25 121.25 0 00353.45 275a132.94 132.94 0 01-125.84-34.14 132.94 132.94 0 01-34.14-125.82 116.93 116.93 0 00-50.72 68c-11.13 41.06 2 84.9 31.17 113.77h34.28c4.8.04 7.24 3.79 7.24 7.54z"
          fill="#f4ec90"
        />
        <path
          d="M307.34 139.27c-.62 1.22-1.28 2.42-2 3.58A53.73 53.73 0 01290.71 158a44.49 44.49 0 01-5.27 3.16 53.28 53.28 0 0111.12 7.84 50.35 50.35 0 0110.71 14l.59-1.07a51.17 51.17 0 0113.39-15.6 51.91 51.91 0 018-5.19 52.5 52.5 0 01-21.91-21.87z"
          fill="#fff"
        />
        <path
          className="cls-3"
          d="M311.46 128.83h-8.31l.4 1.38a15.23 15.23 0 001.18 3.36 53.92 53.92 0 002.61 5.71 61.94 61.94 0 002.55-5.71c.49-1.27 1.58-3.33 1.57-4.74zM334.36 158.84c-1.07.43-2.13.87-3.18 1.35-.66.3-1.29.63-1.92 1a54.17 54.17 0 0010.4 4.2 6.79 6.79 0 01-5.3-6.55z"
        />
        <path
          className="cls-3"
          d="M339.67 165.34a54.17 54.17 0 01-10.4-4.2 51.91 51.91 0 00-8 5.19 51.17 51.17 0 00-13.39 15.6l-.59 1.07c.46.9.91 1.8 1.31 2.72a58.77 58.77 0 012.25 5.78c1 3.07 1.32 2.06-3.54 2.06-5.9 0-4.1 1.15-3.76-1.38.41-3 2.19-6.37 3.73-9.17a50.35 50.35 0 00-10.71-14 53.28 53.28 0 00-11.13-7.79c-1.66.85-3.36 1.62-5.1 2.32-1.3.52-3.33 1.67-4.7 1.63q-2.69 1.13-.69-4V157a10.82 10.82 0 012.74.83 54.18 54.18 0 017.76 3.33 44.49 44.49 0 005.27-3.16 53.73 53.73 0 0014.6-15.19c.74-1.16 1.4-2.36 2-3.58a53.92 53.92 0 01-2.61-5.71 15.23 15.23 0 01-1.18-3.36l-.4-1.38h8.31c0 1.41-1.08 3.47-1.57 4.73a61.94 61.94 0 01-2.55 5.71 52.5 52.5 0 0021.93 21.87c.63-.33 1.27-.66 1.92-1 1-.48 2.11-.92 3.18-1.35-.19-4.58 3.52-9.51 9.29-8a37.08 37.08 0 01-21-15c-4.32-6.59-5-18.48-14.87-18.87-10.58-.41-11.3 12.07-15.76 18.87a37.25 37.25 0 01-19.66 14.61c-4.12 1.38-7.58 3.31-8.83 7.84a10.73 10.73 0 005.19 12.21c3.45 1.9 7.43 2.51 10.91 4.5a37.85 37.85 0 0110.21 8.53 36.13 36.13 0 015.86 9.89c1.78 4.51 2.42 8.91 7.44 11a10.65 10.65 0 0012.16-2.72c2.78-3.21 3.23-7.95 5.2-11.66 4.73-8.91 12.35-14.94 21.79-18.22 4.62-1.6 8.37-4 9.08-9.27a10.71 10.71 0 00-7.7-11.75c9.27 2.84 5.34 17.3-3.99 14.64z"
        />
        <path
          className="cls-3"
          d="M338.29 157.43a10.47 10.47 0 00-2 .68c-.64.23-1.27.48-1.9.73a6.79 6.79 0 005.3 6.51c-3.35-.9 4.19-8.59-1.4-7.92z"
        />
        <path
          className="cls-3"
          d="M334.36 158.84c.63-.25 1.26-.5 1.9-.73a10.47 10.47 0 012-.68c5.59-.67-2 7 1.38 7.91 9.3 2.66 13.27-11.81 4-14.46-5.75-1.55-9.47 3.37-9.28 7.96zM274.94 157v4.15q-2 5.08.69 4c1.37 0 3.4-1.11 4.7-1.63 1.74-.7 3.44-1.46 5.1-2.32a54.18 54.18 0 00-7.76-3.33 10.82 10.82 0 00-2.73-.87zM307.3 193.55c4.86 0 4.53 1 3.54-2.06a58.77 58.77 0 00-2.25-5.78c-.41-.92-.85-1.82-1.31-2.72-1.54 2.8-3.32 6.16-3.73 9.17-.35 2.54-2.15 1.39 3.75 1.39zM199.28 97.69a127.9 127.9 0 00-5.81 17.37 122.32 122.32 0 0116.83-8.9zM227.61 240.89c6.79 6.88 17.4-3.73 10.61-10.61s-17.5 3.81-10.61 10.61z"
        />
        <path
          className="cls-3"
          d="M142.75 183.08a116.93 116.93 0 0150.72-68 127.9 127.9 0 015.81-17.37l11 8.47a122.32 122.32 0 00-16.83 8.9 132.94 132.94 0 0034.14 125.82c-6.88-6.8 3.73-17.4 10.61-10.61-33.59-34-42.83-84.64-24.47-128.6 2.86-6.84-5.89-10.69-11-8.47a131.16 131.16 0 00-70.34 167.31 134.13 134.13 0 0021.23 36.33h20.31c-29.18-28.86-42.32-72.72-31.18-113.78zM362.33 258.2a123.49 123.49 0 01-8.88 16.8 127.87 127.87 0 0017.35-5.81z"
        />
        <path
          className="cls-3"
          d="M227.61 240.89A132.94 132.94 0 00353.45 275a123.49 123.49 0 008.88-16.82l8.47 11a127.87 127.87 0 01-17.35 5.82 121.25 121.25 0 01-17.56 21.82h20.34a130 130 0 0019.06-31.08c2.2-5.09-1.65-13.86-8.47-11-44 18.36-94.59 9.12-128.6-24.47 6.78 6.89-3.82 17.5-10.61 10.62zM372.7 304.35v7.5h34.62c9.65 0 9.67-15 0-15h-51.09q-3.2 3.88-6.69 7.5zM291.8 296.85c-4.83 0-7.24 3.75-7.24 7.5h42.92q4.38-3.55 8.41-7.5H291.8z"
        />
        <path
          className="cls-3"
          d="M327.48 304.35h22.06q3.48-3.62 6.69-7.5h-20.34q-4.02 3.95-8.41 7.5zM349.54 304.35q-3.76 3.91-7.82 7.5h31v-7.5zM284.56 304.35c0 3.75 2.41 7.5 7.24 7.5h25.28a114.54 114.54 0 0010.39-7.5h-42.91z"
        />
        <path
          className="cls-3"
          d="M341.72 311.85q4.06-3.59 7.82-7.5h-22.06a114.54 114.54 0 01-10.39 7.5zM92.68 311.85h34.62v-7.5h32.93q-3.45-3.62-6.62-7.5H92.68c-9.68 0-9.68 15 0 15zM215.44 304.35c0-3.75-2.41-7.5-7.25-7.5h-34.27q4 3.94 8.36 7.5h33.16z"
        />
        <path
          className="cls-3"
          d="M182.28 304.35q-4.37-3.55-8.36-7.5h-20.31q3.17 3.87 6.62 7.5zM160.23 304.35H127.3v7.5H168q-4-3.59-7.77-7.5zM193.73 311.85h14.47c4.83 0 7.24-3.75 7.25-7.5h-33.17a112.32 112.32 0 0010.4 7.5z"
        />
        <path
          className="cls-3"
          d="M168 311.85h24.68a112.32 112.32 0 01-10.4-7.5h-22q3.72 3.91 7.72 7.5zM124.85 344.45h2.45V337h-2.45c-9.65 0-9.67 15 0 15h2.45v-7.5zM375.15 344.45h-2.45V352h2.45c9.65 0 9.67-15 0-15h-2.45v7.5zM255.3 299.15a7.65 7.65 0 00-10.61 0l-5.43 5.2h21.47zM227.07 326.39L250 304.45l24.67 23.61q4.48-.76 8.9-1.86l-22.83-21.85h-21.48L218.69 324q4.16 1.37 8.38 2.39z"
        />
        <path
          className="cls-3"
          d="M291.8 344.45h-11.25q2.88 2.62 5.81 5.16c5 4.36 15.88 2.34 22 2.34h64.34v-7.5zM208.2 352c4.33-.3 7.23-4 10.17-6.86l.67-.64H127.3v7.5h59.29c7.14 0 14.48.44 21.61 0zM208.2 337h-3l-2.3 2.2z"
        />
        <path
          className="cls-3"
          d="M208.2 344.45l6.36-6.08q-4-1.28-7.91-2.81l-1.46 1.44h3l-5.3 2.2 2.3-2.2H127.3v7.5zM296.1 338.19l1 1-1.75-.72c-2 .64-4 1.24-6 1.78l-1.6.42 4 3.83h80.9V337h-73q-1.76.6-3.55 1.19z"
        />
        <path className="cls-3" d="M297.11 339.15l-1-1-.74.24z" />
        <path
          className="cls-3"
          d="M294.81 337l-11.24-10.8q-4.42 1.1-8.9 1.86l13.13 12.57 1.6-.42c2-.54 4-1.15 6-1.78l-3.6-1.43zM218.69 324l-12 11.53q3.91 1.53 7.91 2.81l12.52-12q-4.27-.97-8.43-2.34z"
        />
        <path
          className="cls-3"
          d="M291.8 337l3.56 1.47.74-.24-1.29-1.23zM296.1 338.19q1.79-.59 3.56-1.24h-4.85zM244.7 309.76q2.65 2.53 5.27 5.1l5.33-5.1zM214.55 338.37l-6.36 6.08H219l3.87-3.7q-4.17-1.05-8.32-2.38zM280.55 344.45h11.25l-4-3.83q-4.58 1.18-9.22 2z"
        />
        <path
          className="cls-3"
          d="M227.07 326.39q4.45 1.08 9 1.81L250 314.86q-2.63-2.57-5.27-5.1h10.57l-5.33 5.1c4.9 4.79 9.77 9.64 14.67 14.46q5-.41 10-1.26L250 304.45z"
        />
        <path
          className="cls-3"
          d="M231.53 332.51l4.5-4.31q-4.5-.73-9-1.81l-12.52 12q4.14 1.33 8.36 2.38zM264.65 329.32c4.59 4.51 9.23 9 13.94 13.32q4.63-.84 9.22-2l-13.13-12.57q-5 .83-10.03 1.25zM281.43 377.06H153.5c-9.65 0-9.67 15 0 15h193c9.65 0 9.67-15 0-15zm-127.92 7.5z"
        />
      </g>
    </svg>
  )
}

export default Moon
