import { NavLink } from 'react-router-dom'
import { useEffect, useState } from 'react';
import Moon from '../../assets/svg/Moon';
import Sun from '../../assets/svg/Sun';
import Home from '../../assets/svg/house-solid.svg';
import About from '../../assets/svg/user-solid.svg';
import Portfolio from '../../assets/svg/tag-solid.svg';
import Service from '../../assets/svg/paper-plane-solid.svg';
import Bookamrk from '../../assets/svg/bookmark-solid.svg';
import Contact from '../../assets/svg/phone-solid.svg';


export const Header = () => {
    const [theme, setTheme] = useState<string>('');
    const [scrollClass, setScrollClass] = useState<string>('');
    const [isVisible, setIsVisible] = useState<boolean>(false);

  

    //setting dark mode
    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme) {
            setTheme(savedTheme);
        } else {
            const prefersDarkMode = window.matchMedia('(prefers-color-scheme: light)').matches;
            setTheme(prefersDarkMode ? 'dark' : 'light');
        }
    }, []);
    
    const toggleTheme = (newTheme: string) => {
        setTheme(newTheme);
        localStorage.setItem('theme', newTheme);
    };
    
    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);


    //scrolled
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 0) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    useEffect(() => {
      if (scrolled) {
        document.body.classList.add('scroll');
      } else {
        document.body.classList.remove('scroll');
      }
    }, [scrolled]);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
  return (
    <header id='header' className='header'>
        <nav className='navbar flex justify-between items-center container mx-auto px-5 md:px-3 py-5 md:py-3'>
            <div className='navbar--brand'>
                <h1>D<span>.</span></h1>
            </div>
            <div className='navbar--menu'>
                <ul className='menuList flex gap-0 sm:gap-5 items-center justify-center'>
                    <li>
                        <NavLink to="" onClick={handleScrollToTop}>Home</NavLink>
                    </li>
                    <li>
                        <NavLink to="/#about"> About</NavLink>
                    </li>
                    <li>
                        <NavLink to="/portfolio">Portfolio</NavLink>
                    </li>
                    <li>
                        <NavLink to="/bookmark">Bookmark</NavLink>
                    </li>                   
                    <li>
                        <NavLink to="/#contact">Contact</NavLink>
                    </li>
                </ul>
            </div>
            <div className='navbar--setting'>
                <button onClick={() => toggleTheme('dark')} className='btn__dark' ><Moon/> </button>
                <button onClick={() => toggleTheme('light')}className='btn__light' ><Sun/></button>
            </div>
        </nav>
    </header>
  )
}

export default Header