import { useEffect, useRef } from "react";
import HeroContact from "../../assets/img/contact.png";
import { useLocation } from "react-router-dom";
import Linkedin from "../../assets/svg/Linkedin";
import Email from "../../assets/svg/Email";
import Mountain from "../../assets/svg/Mountain";

export const Contact = () => {
  const aboutRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#contact" && aboutRef.current) {
      const headerHeight = 64; // Adjust this value to match your header's height
      const padding = 20; // Adjust this value to add the desired padding at the top
      const offset = aboutRef.current.getBoundingClientRect().top;
      const scrollPosition = offset - headerHeight - padding;
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [location]);
  return (
    <section id="contact" ref={aboutRef} className="relative">
      <div className="contact__box wrapper  container mx-auto px-5 block lg:flex gap-10">
        <div className="contact__box--left mb-5">
          <div className="hero--contact">
            <img src={HeroContact} />
          </div>
        </div>
        <div className="contact__box--right">
          <h1 className="text-4xl mb-5 heading">have a project ?</h1>
          <p className="text-3xl subheading">Let's Collaboration with Me</p>
          <ul className="my-5 item--contact">
            <li>
              <label>
                {" "}
                <span>
                  <Email />
                </span>{" "}
                Email
              </label>{" "}
              : diaslaksmana@gmail.com
            </li>
            {/* <li>
                      <label> <span><Linkedin/></span> LinkedIn </label> : <a href="https://www.linkedin.com/in/dwikydiasl/" target='_blank'>dwikydiasl</a>
                  </li> */}
          </ul>
        </div>
      </div>
      <div className="bgFooter">
        <Mountain />
      </div>
    </section>
  );
};

export default Contact;
