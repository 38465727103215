// TabPills.tsx

import React, { useState } from 'react';

interface Tab {
  id: string;
  title: string;
  content: React.ReactNode;
}

interface TabPillsProps {
  tabs: Tab[];
}

const TabPills: React.FC<TabPillsProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0]?.id);

  const handleTabClick = (id: string) => {
    setActiveTab(id);
  };

  return (
    <div className=" block lg:flex  gap-5">
      <div className="tabs block sm:flex gap-5 justify-center lg:block basis-1/6  pt-5">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => handleTabClick(tab.id)}
            className={`text-base mb-5 font-medium ${
              activeTab === tab.id
                ? ' active'
                : ' text-gray-600'
            } focus:outline-none`}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className="basis-5/6 pt-5">
        {tabs.map((tab) => (
          <div key={tab.id} className={activeTab === tab.id ? 'block' : 'hidden'}>
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabPills;
