import { Link, NavLink } from "react-router-dom";
import useTechData from "../../../hooks/FetchData";
import { DataPortfolio } from "../../../constan/DataPortfolio";

export const Company = () => {
  const filteredData = DataPortfolio.filter(
    (entry: any) => entry.category === "Company Profile"
  );

  return (
    <div className="company">
      {DataPortfolio && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredData?.map((item) => (
            <div className="card" key={item.id}>
              <Link to={`/detail/${item.id}`}></Link>
              <div className="card--header">
                <img src={item.image} alt="cover" />
                <p className="desc">{item.description}</p>
              </div>
              <div className="card--body">
                <ul className="flex gap-2 techStack">
                  {item?.tech?.map((tech: any) => (
                    <li>#{tech.name}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default Company;
