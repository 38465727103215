import React from 'react'
import Company from '../layout/portfolio/Company';
import Ecommerce from '../layout/portfolio/Ecommerce';
import Other from '../layout/portfolio/Other';
import BrushIcon from '../../assets/svg/BrushIcon';
import TabPills from '../component/TabPills';
import { NavLink } from 'react-router-dom';

export const Portfolio = () => {
  const tabs = [
    {
      id: 'tab1',
      title: 'Company Profil',
      content: <Company/>,
    },
    {
      id: 'tab2',
      title: 'E-Commerce',
      content: <Ecommerce/>,
    },
    {
      id: 'tab3',
      title: 'Other',
      content: <Other/>,
    },
  ];
  return (
    <section id='portfolio'>
        <div className='portfolio wrapper container  mx-auto px-5 md:px-3 mb-10 mt-10'>
          <div className='breadcrumb mb-8'>
            <ul>
              <li><NavLink to="/">Home</NavLink></li>
              <li>Portfolio</li>
            </ul>
          </div>
          <TabPills tabs={tabs} />
        </div>
    </section>
  )
}

export default Portfolio